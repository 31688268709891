import axios from 'axios';

export const axiosClient = axios.create({
  baseURL: "https://be-prod.swishbuilder.io",
  timeout: 30 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});


