import styles from './page-not-found.scss';

export function NotFoundPage() {
    return (
        <div className="not-found-page-container">
            <div className='not-found-content'>
                <h1>404</h1>
                <h3>Page Not Found</h3>
            </div>
        </div>
    );
}
export default NotFoundPage;