import { Button, Modal } from "antd";
import successIcon from '../../assets/icons/success_notification.jpeg'
import './thank-you.scss'
import { CheckCircleOutlined } from "@ant-design/icons";

export default function Thankyou({ form, setUploadedCvURL, setFileList, openThankYou, setOpenThankYou }) {


    const handleClose = () => {
        form.resetFields();
        setUploadedCvURL(null);
        setFileList([]);
        setOpenThankYou(false);
    }

    return (
        <Modal
            open={openThankYou}
            footer={null}
            onCancel={handleClose}
        >
            <div className="thank-you-container">
                <CheckCircleOutlined className="icon-success" />
                <div className="middle-content">
                    <p className="thankyou-heading">Thank you for your application.</p>
                    <p className="thankyou-email">You will receive an email soon.</p>
                </div>
                <Button onClick={handleClose}>Close</Button>
            </div>
        </Modal>
    )
}