import { templateApi } from "./api/template-api"

const getTemplateDetails = async (url) => {
    return await templateApi.getTemplateDetails(url)
}

const uploadCv = async (imageData) => {
    return await templateApi.uploadCv(imageData)
}

const applyJob = async (data) => {
    return await templateApi.applyJob(data)
}

const getCountry = async () => {
    return await templateApi.getCountry();
};

const getState = async (params) => {
    return await templateApi.getState(params);
};

const getCity = async (params) => {
    return await templateApi.getCity(params);
};

export const templateService = {
    getTemplateDetails,
    uploadCv,
    applyJob,
    getCountry,
    getState,
    getCity
}