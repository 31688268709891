import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { TemplateContext2Provider } from "../../contexts/TemplateContext2";
import { setFavicon } from "../../../helpers/set-chrome-icon";

export default function TemplateTwoLogic() {

    const templateData = useSelector(state => state.getTemplateDetailsReducer?.data);

    const {

        setTemplateData,

        setRowOneImageLink,
        setRowOneButtonColor,
        setRowOneButtonText,
        setRowOneFontColor,

        setRowTwoDescription,
        setRowTwoBackgroundColor,
        setRowTwoValue,
        setRowTwoImageLink,
        setRowTwoOpaqueValue,

        setRowThreeImageLink,
        setRowThreeBackgroundColor,
        setRowThreeDescription,
        setRowThreeOpaqueValue,
        setRowThreeButtonColor,
        setRowThreeButtonText,
        setRowThreeFontColor,

        setRowFourDescription,
        setRowFourBackgroundColor,
        setRowFourValue,
        setRowFourImageLink,
        setRowFourOpaqueValue,

        setRowFiveImageLink,
        setRowFiveBackgroundColor,
        setRowFiveDescription,
        setRowFiveOpaqueValue,
        setRowFiveButtonColor,
        setRowFiveButtonText,
        setRowFiveFontColor,

        setRowResizeImageLink,
        setRowResizeDescription,
        setRowResizeBackgroundColor,
        setRowResizeOpaqueValue,
        setWidthPercentage,

        setMiddleContainerImageLink,
        setMiddleContainerBackgroundColor,
        setValue,

        setRowSixImageLink,
        setRowSixDescription,
        setRowSixBackgroundColor,
        setRowSixOpaqueValue,

        setRowSevenImageLink,
        setRowSevenDescription,
        setRowSevenBackgroundColor,
        setRowSevenOpaqueValue,

        setRowEightImageLink,
        setRowEightDescription,
        setRowEightBackgroundColor,
        setRowEightOpaqueValue,

        setRowNineAlignment,
        setRowNineHeaderText,
        setRowNineFooterText,

        setRowTenImageLink,
        setListChecked,

        setQuestions,
        setContainerValue,
        setContainerBackgroundColor,
        setContainerBackgroundImage,

        setQuestionBackgroundColor,
        setQuestionsFontColor,
        setQuestionFormOpaqueValue,
        setQuestionBackgroundImage,
        setQuestionFormValue,

        setRowOneHeight,
        setRowOneWidthPercentage,
        setRowTwoHeight,
        setRowTwoWidthPercentage,
        setRowThreeHeight,
        setRowThreeWidthPercentage,
        setRowFourHeight,
        setRowFourWidthPercentage,
        setRowFiveHeight,
        setRowFiveWidthPercentage,
        setRowTenHeight,
        setRowTenWidthPercentage,

        setRowOneHideApply,
        setRowThreeHideApply,
        setRowFiveHideApply,

        clientLogo,
        setClientLogo,
        setCustomFormContent,
        setCustomForm,
    } = useContext(TemplateContext2Provider);


    const setContactInfoGet = (templateDetailsData) => {
        const contactInfo = templateDetailsData?.contactInfo;
        if (contactInfo) {
            const trueKeys = Object.keys(contactInfo).filter(key => contactInfo[key] === true);
            const selectedKeys = trueKeys.map(item => {
                switch (item) {
                    case 'isRequireCity':
                        return 'City';
                    case 'isRequireEmail':
                        return 'Email';
                    case 'isRequireState':
                        return 'State';
                    case 'isRequireResume':
                        return 'Upload Resume';
                    case 'isRequireCountry':
                        return 'Country';
                    case 'isRequireZipCode':
                        return 'Zipcode';
                    case 'isRequireLastName':
                        return 'Last Name';
                    case 'isRequireFirstName':
                        return 'First Name';
                    case 'isRequirePhoneNumber':
                        return 'Phone Number';
                    default:
                        return '';
                }
            }).filter(item => item !== '');
            return selectedKeys;
        }
        return [];
    };

    const checkBoxOpaqueDecider = (value) => {
        if (value === true || value === "true" || value === 1 || value === "1") {
            return true;
        } else if (value === false || value === "false" || value === 0 || value === "0") {
            return false;
        }
    };

    useEffect(()=> {
        setFavicon(clientLogo);
    },[clientLogo])

    useEffect(() => {
        if (templateData) {

            //custom form
            const { customForm, customFormContent } = templateData;
            setCustomFormContent(customFormContent);
            setCustomForm(checkBoxOpaqueDecider(customForm))

            // states :
            const {
                rowOne = {},
                rowTwo = {},
                rowThree = {},
                rowFour = {},
                rowFive = {},
                rowResize = {},
                middleContainer = {},
                rowSix = {},
                rowSeven = {},
                rowEight = {},
                rowNine={},
                rowTen = {},
            } = templateData?.templatetwo || {};

            setClientLogo(templateData?.client?.clientLogo)

            setRowOneImageLink(rowOne?.image);
            setRowOneButtonColor(rowOne?.buttonColor ? rowOne?.buttonColor : '#11295a');
            setRowOneButtonText(rowOne?.buttonText ? rowOne?.buttonText : 'APPLY NOW');
            setRowOneFontColor(rowOne?.buttonFontColor ? rowOne?.buttonFontColor : 'white');
            setRowOneHideApply(checkBoxOpaqueDecider(rowOne?.hideApply))
            setRowOneHeight(rowOne?.height)
            setRowOneWidthPercentage(rowOne?.widthPercentage)

            setTemplateData(templateData);
            

            setRowTwoDescription(rowTwo?.descriptionContent);
            setRowTwoBackgroundColor(rowTwo?.bgcolor ? rowTwo?.bgcolor : 'white');
            setRowTwoHeight(rowTwo?.height);
            setRowTwoWidthPercentage(Number(rowTwo?.widthPercentage));
            setRowTwoValue(rowTwo?.value ? Number(rowTwo?.value) : 1);
            setRowTwoImageLink(rowTwo?.image);
            setRowTwoOpaqueValue(checkBoxOpaqueDecider(rowTwo?.opaque));

            setRowThreeImageLink(rowThree?.image);
            setRowThreeBackgroundColor(rowThree?.bgcolor ? rowThree?.bgcolor : 'white');
            setRowThreeDescription(rowThree?.descriptionContent);
            setRowThreeOpaqueValue(checkBoxOpaqueDecider(rowThree?.opaque));
            setRowThreeButtonColor(rowThree?.buttonColor ? rowThree?.buttonColor : '#11295a');
            setRowThreeButtonText(rowThree?.buttonText ? rowThree?.buttonText : 'APPLY NOW');
            setRowThreeFontColor(rowThree?.buttonFontColor ? rowThree?.buttonFontColor : 'white');
            setRowThreeHideApply(checkBoxOpaqueDecider(rowThree?.hideApply))

            setRowFourDescription(rowFour?.descriptionContent);
            setRowFourBackgroundColor(rowFour?.bgcolor ? rowFour?.bgcolor : 'white');
            setRowFourHeight(rowFour?.height);
            setRowFourWidthPercentage(Number(rowFour?.widthPercentage));
            setRowFourValue(rowFour?.value ? Number(rowFour?.value) : 1);
            setRowFourImageLink(rowFour?.image);
            setRowFourOpaqueValue(checkBoxOpaqueDecider(rowFour?.opaque));
            setRowThreeHeight(rowThree?.height);
            setRowThreeWidthPercentage(Number(rowThree?.widthPercentage))

            setRowFiveImageLink(rowFive?.image);
            setRowFiveBackgroundColor(rowFive?.bgcolor ? rowFive?.bgcolor : 'white');
            setRowFiveDescription(rowFive?.descriptionContent);
            setRowFiveOpaqueValue(checkBoxOpaqueDecider(rowFive?.opaque));
            setRowFiveButtonColor(rowFive?.buttonColor ? rowFive?.buttonColor : '#11295a');
            setRowFiveButtonText(rowFive?.buttonText ? rowFive?.buttonText : 'APPLY NOW');
            setRowFiveFontColor(rowFive?.buttonFontColor ? rowFive?.buttonFontColor : 'white');
            setRowFiveHeight(rowFive?.height);
            setRowFiveWidthPercentage(Number(rowFive?.widthPercentage));
            setRowFiveHideApply(checkBoxOpaqueDecider(rowFive?.hideApply));

            setRowResizeImageLink(rowResize?.image);
            setRowResizeDescription(rowResize?.descriptionContent);
            setRowResizeBackgroundColor(rowResize?.bgcolor ? rowResize?.bgcolor : 'white');
            setRowResizeOpaqueValue(checkBoxOpaqueDecider(rowResize?.opaque));
            setWidthPercentage(rowResize?.width ? rowResize?.width : 100);

            setMiddleContainerImageLink(middleContainer?.image)
            setMiddleContainerBackgroundColor(middleContainer?.bgcolor ? middleContainer?.bgcolor : 'white');
            setValue(middleContainer?.value ? Number(middleContainer?.value) : 1);

            setRowSixImageLink(rowSix?.image);
            setRowSixDescription(rowSix?.descriptionContent);
            setRowSixBackgroundColor(rowSix?.bgcolor ? rowSix?.bgcolor : 'white');
            setRowSixOpaqueValue(checkBoxOpaqueDecider(rowSix?.opaque));

            setRowSevenImageLink(rowSeven?.image);
            setRowSevenDescription(rowSeven?.descriptionContent);
            setRowSevenBackgroundColor(rowSeven?.bgcolor ? rowSeven?.bgcolor : 'white');
            setRowSevenOpaqueValue(checkBoxOpaqueDecider(rowSeven?.opaque));

            setRowNineAlignment(rowNine?.alignment ? rowNine?.alignment : 'center');
            setRowNineHeaderText(rowNine?.headerText ? rowNine?.headerText : 'APPLY NOW');
            setRowNineFooterText(rowNine?.footerText ? rowNine?.footerText : 'SUBMIT');

            setRowEightImageLink(rowEight?.image);
            setRowEightDescription(rowEight?.descriptionContent);
            setRowEightBackgroundColor(rowEight?.bgcolor ? rowEight?.bgcolor : 'white');
            setRowEightOpaqueValue(checkBoxOpaqueDecider(rowEight?.opaque));

            setRowTenImageLink(rowTen?.image);
            setRowTenHeight(rowTen?.height);
            setRowTenWidthPercentage(rowTen?.widthPercentage);

            //contactForm
            const selectedKeys = setContactInfoGet(templateData);
            setListChecked(selectedKeys);

            setQuestions(templateData?.questions);

            //container styles
            setContainerBackgroundColor(templateData?.styles?.backgroundColor ? templateData?.styles?.backgroundColor : 'white');
            setContainerBackgroundImage(templateData?.styles?.background_image);
            setContainerValue(templateData?.styles?.background_mode ? Number(templateData?.styles?.background_mode) : 1)

            //background colors
            const { backgroundColor, backgroundImage, value, fontColor, opaque } = templateData?.styles?.questionFormStyle
            setQuestionBackgroundColor(backgroundColor ? backgroundColor : 'white');
            setQuestionsFontColor(fontColor ? fontColor : 'black');
            setQuestionFormOpaqueValue(checkBoxOpaqueDecider(opaque));
            setQuestionBackgroundImage(backgroundImage);
            setQuestionFormValue(value ? Number(value) : 1)

        };
    }, [templateData])

    return null;
}