import { useContext } from "react";
import { TemplateContext2Provider } from "../../contexts/TemplateContext2";
import { Button } from "antd";
import parse from "html-react-parser";
import TemplateTwoLogic from "./template-logic";
import QuestionReUsable from "../../components/re-usable/questions-resuable";
import { ImageOrColorDecider, OpaqueDeciderForDescription, OpaqueDeciderForQuestionForm, resizeImageOrColorDecider } from "../../../helpers/container-style-decider";
import { checkIfAnyStateHasValue, getDescription } from "../../../helpers/description-empty";
import './template-two.scss';

export default function TemplateTwo() {
    const {
        rowOneImageLink,

        rowTwoValue,
        rowTwoDescription,
        rowTwoImageLink,
        rowTwoBackgroundColor,
        rowTwoOpaqueValue,

        rowThreeImageLink,
        rowThreeDescription,
        rowThreeBackgroundColor,
        rowThreeOpaqueValue,

        rowFourImageLink,
        rowFourDescription,
        rowFourOpaqueValue,
        rowFourBackgroundColor,
        rowFourValue,

        rowFiveImageLink,
        rowFiveDescription,
        rowFiveOpaqueValue,
        rowFiveBackgroundColor,

        rowSixImageLink,
        rowSixDescription,
        rowSixOpaqueValue,
        rowSixBackgroundColor,

        rowSevenImageLink,
        rowSevenDescription,
        rowSevenOpaqueValue,
        rowSevenBackgroundColor,

        rowEightImageLink,
        rowEightDescription,
        rowEightOpaqueValue,
        rowEightBackgroundColor,

        rowTenImageLink,
        middleContainerImageLink,
        middleContainerBackgroundColor,
        value,

        rowResizeDescription,
        rowResizeOpaqueValue,
        rowResizeImageLink,
        rowResizeBackgroundColor,
        widthPercentage,

        listChecked,
        questionBackgroundColor,
        questionsFontColor,
        questions,
        templateData,
        questionFormOpaqueValue,
        containerValue,
        containerBackgroundColor,
        containerBackgroundImage,
        setQuestions,
        rowOneButtonColor,
        rowOneButtonText,
        rowOneFontColor,
        rowThreeButtonColor,
        rowThreeFontColor,
        rowThreeButtonText,
        rowFiveButtonColor,
        rowFiveFontColor,
        rowFiveButtonText,

        questionFormValue,
        questionBackgroundImage,

        rowOneHeight,
        rowOneWidthPercentage,
        rowTwoHeight,
        rowTwoWidthPercentage,
        rowThreeHeight,
        rowThreeWidthPercentage,
        rowFourHeight,
        rowFourWidthPercentage,
        rowFiveHeight,
        rowFiveWidthPercentage,
        rowTenHeight,
        rowTenWidthPercentage,

        rowOneHideApply,
        rowThreeHideApply,
        rowFiveHideApply,

        customForm,
        customFormContent,

        rowNineFooterText,
    } = useContext(TemplateContext2Provider);

    const scrollToNine = () => {
        const nineDiv = document.getElementById(
            `preview-row-nine-template-2-scroll`
        );
        if (nineDiv) {
            nineDiv.scrollIntoView({ behavior: "smooth" });
        }
    };

    const buttonStyle = (background, color) => {
        return {
            background: background,
            color: color,
        }
    };

    const resizeCss = (height, widthPercentage, widthLimit, heightLimit) => {
        return {
            height: `auto`,
            width: widthPercentage > 0 && `${Math.min(widthPercentage, 100)}%`,
            marginLeft: "auto",
            marginRight: "auto",
        }
    };

    const middleContainerVariables = [
        rowSixImageLink,
        getDescription(rowSixDescription),
        rowSevenImageLink,
        getDescription(rowSevenDescription),
        rowEightImageLink,
        getDescription(rowEightDescription),
        middleContainerImageLink,
        // middleContainerBackgroundColor
    ];

    return (
        <>
            <TemplateTwoLogic />
            <div
                className="template-two-preview-container"
                style={ImageOrColorDecider(
                    containerValue,
                    containerBackgroundImage,
                    containerBackgroundColor
                )}
            >
                {!customForm ? (
                    <>
                        {rowOneImageLink && (
                            <div className="preview-row-one-container">
                                {!rowOneHideApply && (
                                    <div className="apply-button">
                                        <Button style={buttonStyle(rowOneButtonColor, rowOneFontColor)} onClick={() => scrollToNine()}>{rowOneButtonText}</Button>
                                    </div>
                                )}
                                <>
                                    <img
                                        src={rowOneImageLink}
                                        alt="Uploaded"
                                        style={resizeCss(rowOneHeight, rowOneWidthPercentage, 156, 156)}
                                    />
                                </>
                            </div>
                        )}
                        {(rowTwoImageLink || rowTwoDescription) && (
                            <div
                                className={rowTwoValue == 0 ? "preview-row-two-container" : "preview-row-two-container-without-min-height"}
                                style={resizeImageOrColorDecider(
                                    rowTwoValue, rowTwoBackgroundColor,
                                )}
                            >
                                {rowTwoDescription && (
                                    <div className="description-container">
                                        <div
                                            className="description"
                                            style={rowTwoValue == 1 ? OpaqueDeciderForDescription(
                                                rowTwoOpaqueValue,
                                                rowTwoBackgroundColor
                                            ) : { background: 'none' }}
                                        >
                                            {parse(`${rowTwoDescription}`)}
                                        </div>
                                    </div>
                                )}
                                {rowTwoValue == 0 && rowTwoImageLink && (
                                    <div className="preview-row-ten-container">
                                        <img alt="preview-row-ten" src={rowTwoImageLink} style={resizeCss(rowTwoHeight, rowTwoWidthPercentage, 300, 300)}></img>
                                    </div>
                                )}
                            </div>
                        )}
                        {(rowThreeImageLink || rowThreeDescription) && (
                            <div className={rowThreeImageLink ? "preview-row-three-container" : "preview-row-three-container-without-min-height"}>
                                {rowThreeDescription && (
                                    <div className="description-container">
                                        <div
                                            className="description"
                                            style={OpaqueDeciderForDescription(
                                                rowThreeOpaqueValue,
                                                rowThreeBackgroundColor
                                            )}
                                        >
                                            {parse(`${rowThreeDescription}`)}
                                        </div>
                                        {!rowThreeHideApply && (
                                            <div className="apply-button">
                                                <Button style={buttonStyle(rowThreeButtonColor, rowThreeFontColor)} onClick={() => scrollToNine()}>
                                                    {rowThreeButtonText}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {rowThreeImageLink && (
                                    <div className="preview-row-ten-container">
                                        <img alt="preview-row-ten"
                                            src={rowThreeImageLink}
                                            style={resizeCss(rowThreeHeight, rowThreeWidthPercentage, 600, 450)}
                                        ></img>
                                    </div>
                                )}
                            </div>
                        )}
                        {(rowFourImageLink || rowFourDescription) && (
                            <div
                                className={rowFourValue == 0 ? "preview-row-four-container" : "preview-row-four-container-without-min-height"}
                                style={resizeImageOrColorDecider(
                                    rowFourValue, rowFourBackgroundColor,
                                )}
                            >
                                {rowFourDescription && (
                                    <div className="description-container">
                                        <div
                                            className="description"
                                            style={rowFourValue == 1 ? OpaqueDeciderForDescription(
                                                rowFourOpaqueValue,
                                                rowFourBackgroundColor
                                            ) : { background: 'none' }}
                                        >
                                            {parse(`${rowFourDescription}`)}
                                        </div>
                                    </div>
                                )}
                                {rowFourValue == 0 && rowFourImageLink && (
                                    <div className="preview-row-ten-container">
                                        <img alt="preview-row-ten" src={rowFourImageLink} style={resizeCss(rowFourHeight, rowFourWidthPercentage, 300, 300)}></img>
                                    </div>
                                )}
                            </div>
                        )}
                        {(rowFiveImageLink || rowFiveDescription) && (
                            <div className={rowFiveImageLink ? "preview-row-five-container" : "preview-row-five-container-without-min-height"}
                            >
                                {rowFiveDescription && (
                                    <div className="description-container">
                                        <div
                                            className="description"
                                            style={OpaqueDeciderForDescription(
                                                rowFiveOpaqueValue,
                                                rowFiveBackgroundColor
                                            )}
                                        >
                                            {parse(`${rowFiveDescription}`)}
                                        </div>
                                        {!rowFiveHideApply && (
                                            <div className="apply-button">
                                                <Button style={buttonStyle(rowFiveButtonColor, rowFiveFontColor)} onClick={() => scrollToNine()}>{rowFiveButtonText}</Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {rowFiveImageLink && (
                                    <div className="preview-row-ten-container">
                                        <img alt="preview-row-ten"
                                            src={rowFiveImageLink}
                                            style={resizeCss(rowFiveHeight, rowFiveWidthPercentage, 600, 450)}
                                        ></img>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={checkIfAnyStateHasValue([rowResizeImageLink, getDescription(rowResizeDescription)]) ? "preview-row-resize-container" : 'no-display'}>
                            <div
                                className="description-container"
                                style={{
                                    ...OpaqueDeciderForDescription(
                                        rowResizeOpaqueValue,
                                        rowResizeBackgroundColor
                                    ),
                                    width: `${widthPercentage}%`,
                                }}
                            >
                                <div className="description">
                                    {rowResizeDescription && parse(`${rowResizeDescription}`)}
                                </div>
                            </div>
                            {rowResizeImageLink && (
                                <div className="image">
                                    <img alt="preview-row-resize" src={rowResizeImageLink}></img>
                                </div>
                            )}
                        </div>
                        <div className={checkIfAnyStateHasValue(middleContainerVariables) ? "preview-middle-container-template-2" : 'no-display'}
                            style={ImageOrColorDecider(
                                value,
                                middleContainerImageLink,
                                middleContainerBackgroundColor,
                            )}
                        >
                            <div className="content">
                                <div className={checkIfAnyStateHasValue([rowSixImageLink, getDescription(rowSixDescription)]) ? "preview-row-six-container" : 'no-display'}>
                                    <div className="image-description-container">
                                        <div className="image">
                                            {rowSixImageLink && (
                                                <img alt="row-six" src={rowSixImageLink}></img>
                                            )}
                                        </div>
                                        <div
                                            className="description"
                                            style={OpaqueDeciderForDescription(
                                                rowSixOpaqueValue,
                                                rowSixBackgroundColor
                                            )}
                                        >
                                            {rowSixDescription && parse(`${rowSixDescription}`)}
                                        </div>
                                    </div>
                                </div>
                                <div className={checkIfAnyStateHasValue([rowSevenImageLink, getDescription(rowSevenDescription)]) ? "preview-row-seven-container" : 'no-display'}>
                                    <div className="image-description-container">
                                        <div
                                            className="description"
                                            style={OpaqueDeciderForDescription(
                                                rowSevenOpaqueValue,
                                                rowSevenBackgroundColor
                                            )}
                                        >
                                            {rowSevenDescription && parse(`${rowSevenDescription}`)}
                                        </div>
                                        <div className="image">
                                            {rowSevenImageLink && (
                                                <img alt="row-seven" src={rowSevenImageLink}></img>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={checkIfAnyStateHasValue([rowEightImageLink, getDescription(rowEightDescription)]) ? "preview-row-eight-container" : 'no-display'}>
                                    <div className="image-description-container">
                                        <div className="image">
                                            {rowEightImageLink && (
                                                <img alt="row-eight" src={rowEightImageLink}></img>
                                            )}
                                        </div>
                                        <div
                                            className="description"
                                            style={OpaqueDeciderForDescription(
                                                rowEightOpaqueValue,
                                                rowEightBackgroundColor
                                            )}
                                        >
                                            {rowEightDescription && parse(`${rowEightDescription}`)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="custom-form">
                        {customFormContent && (
                            parse(customFormContent)
                        )}
                    </div>
                )}
                <div
                    className="preview-row-nine-container"
                    style={questionFormOpaqueValue ? OpaqueDeciderForQuestionForm(
                        questionFormOpaqueValue,
                        questionBackgroundColor
                    ) : ImageOrColorDecider(questionFormValue, questionBackgroundImage, questionBackgroundColor)}
                    id="preview-row-nine-template-2-scroll"
                >
                    <QuestionReUsable templateData={templateData} questions={questions} questionsFontColor={questionsFontColor} listChecked={listChecked} setQuestions={setQuestions} temlateType={2} noSpace={true} heading={rowNineFooterText} questionBackgroundColor={questionBackgroundColor} />
                </div>
                {!customForm && (
                    <>
                        {rowTenImageLink && (
                            <div className="preview-row-ten-container">
                                <img alt="preview-row-ten" src={rowTenImageLink} style={resizeCss(rowTenHeight, rowTenWidthPercentage, 156, 156)}></img>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}