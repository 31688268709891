import { templateConstants } from "../constants/template-constants";

const initialState = {
    country: null,
    id: null, city: null, state: null, users: null, name: null, userid: null, status: false
}

export const getTemplateDetailsReducer = (state = { data: null, loading: false }, action) => {
    switch (action.type) {
        case templateConstants.GET_TEMPLATE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case templateConstants.GET_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload?.data
            };
        case templateConstants.GET_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        default: return state
    }
}

export const getCountryreducer = (state = initialState, action) => {
    switch (action.type) {
        case templateConstants.GET_COUNTRY_LIST:
            return {
                status: true,
                country: null,
            };
        case templateConstants.GET_COUNTRY_LIST_SUCCESS:
            return {
                status: false,
                country: action.payload,
            };
        case templateConstants.GET_COUNTRY_LIST_FAILURE:
            return {
                status: false,
                country: null,
            };
        default:
            return state;
    }
};


export const getCityreducer = (city = initialState, action) => {
    switch (action.type) {
        case templateConstants.GET_CITY_LIST:
            return {
                status: true,
                city: null,
            };
        case templateConstants.GET_CITY_LIST_SUCCESS:
            return {
                status: false,
                city: action.payload,
            };
        case templateConstants.GET_CITY_LIST_FAILURE:
            return {
                status: false,
                city: null,
            };
        default:
            return city;
    }
};

export const getStatereducer = (state = initialState, action) => {
    switch (action.type) {
        case templateConstants.GET_STATE_LIST:
            return {
                status: true,
                state: null,
            };
        case templateConstants.GET_STATE_LIST_SUCCESS:
            return {
                status: false,
                state: action.payload,
            };
        case templateConstants.GET_STATE_LIST_FAILURE:
            return {
                status: false,
                state: null,
            };
        default:
            return state;
    }
};



