import { Button, Form, notification } from "antd";
import PreviewInputs from "../../pages/preview-inputs/preview-inputs";
import QuestionPreview from "../../pages/questions/QuestionPreview";
import { templateAction } from "../../../actions/template-action";
import { useDispatch } from "react-redux";
import Thankyou from "../../pages/thank-you/thank-you";
import { useContext, useState } from "react";
import { TemplateContext2Provider } from "../../contexts/TemplateContext2";

export default function QuestionReUsable({
  templateData,
  questions,
  setQuestions,
  questionBackgroundColor,
  questionsFontColor,
  listChecked,
  OpaqueDeciderForQuestionForm = () => null,
  temlateType,
  noSpace = false,
  heading = "APPLY NOW",
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [openThankYou, setOpenThankYou] = useState(false);
  const [uploadedCvURL, setUploadedCvURL] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { rowNineAlignment, rowNineHeaderText, rowNineFooterText } = useContext(
    TemplateContext2Provider
  );

  const getQuestionAnswer = (questions) => {
    const data = [];
    questions?.forEach((item) => {
      if (item.type === 1) {
        data.push({
          questionId: item.id,
          optionId: null,
          answerText: item.answerText || "",
        });
      } else {
        const optionsData = item?.optionQuestions
          .filter((option) => option.isAnswer)
          .map((option) => ({
            questionId: item.id,
            optionId: option.id,
            answerText: option.name || "",
          }));
        data.push(...optionsData);
      }
    });
    return { applicantAnswer: data };
  };

  const onFinish = (values) => {
    let data = {
      ...values,
      templateId: templateData?.id,
      cvUrl: uploadedCvURL,
    };
    let questionsData = getQuestionAnswer(questions);
    if (questionsData) {
      data = {
        ...data,
        applicantAnswer: questionsData.applicantAnswer,
      };
    }
    const filteredData = Object.keys(data).reduce((acc, key) => {
      if (!key.includes("question_")) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    dispatch(
      templateAction.applyJob(filteredData, applyJobSuccces, applyJobFailure)
    );
  };

  const applyJobSuccces = (res) => {
    const redirectUrl = templateData?.redirectUrl;

    if (redirectUrl) {
      const baseUrl = "http://";
      const fullUrl = baseUrl + redirectUrl;

      window.open(fullUrl, "_blank");
    } else {
      setOpenThankYou(true);
    }
  };
  const applyJobFailure = (res) => {
    notification.error({
      message: res?.response?.data?.message,
    });
  };

  return (
    <>
      <div className="question-form">
        <div
          className="question-form-content"
          style={{
            ...OpaqueDeciderForQuestionForm(),
            marginLeft:
              rowNineAlignment === "center"
                ? "auto"
                : rowNineAlignment === "left"
                ? "0"
                : "auto",
            marginRight:
              rowNineAlignment === "center"
                ? "auto"
                : rowNineAlignment === "left"
                ? "auto"
                : "0",
          }}
        >
          <div className="set-width">
            {noSpace ? (
              <div
                style={{
                  color: questionsFontColor ? questionsFontColor : "black",
                  paddingBottom: "4%",
                  textAlign: "center",
                  lineHeight: "40px",
                }}
              >
                <div style={{ fontSize: "20px", fontWeight: "900" }}>
                  {rowNineHeaderText}
                </div>
              </div>
            ) : (
              <p
                style={{
                  color: questionsFontColor ? questionsFontColor : "black",
                }}
              >
                {rowNineHeaderText}
              </p>
            )}
            <Form
              name="applyForm"
              form={form}
              onFinish={onFinish}
              layout="vertical"
            >
              <PreviewInputs
                listChecked={listChecked}
                form={form}
                fileList={fileList}
                setFileList={setFileList}
                setUploadedCvURL={setUploadedCvURL}
                questionsFontColor={questionsFontColor}
                templateType={temlateType}
                noSpace={noSpace}
              />
              {questions && questions.length > 0 && (
                <div
                  className="questions-preview-content"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "10px",
                    color: questionsFontColor && questionsFontColor,
                  }}
                >
                  Pre Qualify
                </div>
              )}
              {questions &&
                questions.length > 0 &&
                questions.map((item, index) => (
                  <QuestionPreview
                    type={item.type}
                    dataQuestion={item}
                    index={index}
                    key={index}
                    questionsFontColor={questionsFontColor}
                    questions={questions}
                    setQuestions={setQuestions}
                  />
                ))}
              <Form.Item>
                <div className="apply-preview">
                  <Button
                    style={
                      temlateType == 2 && {
                        background: questionsFontColor,
                        color: questionBackgroundColor,
                        border: "none",
                        height: "40px",
                      }
                    }
                    htmlType="submit"
                  >
                    {heading}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Thankyou
        form={form}
        openThankYou={openThankYou}
        setOpenThankYou={setOpenThankYou}
        setFileList={setFileList}
        setUploadedCvURL={setUploadedCvURL}
      />
    </>
  );
}
