export const setFavicon = (imageUrl) => {
    const updateFavicon = () => {
        const link = document.querySelector("link[rel~='icon']");
        if (link !== null) {
            link.href = imageUrl;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = imageUrl;
            document.head.appendChild(newLink);
        }
    };
    updateFavicon();
};
