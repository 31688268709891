export const templateConstants = {
    GET_TEMPLATE_DETAILS_REQUEST: 'GET_TEMPLATE_DETAILS_REQUEST',
    GET_TEMPLATE_DETAILS_SUCCESS: 'GET_TEMPLATE_DETAILS_SUCCESS',
    GET_TEMPLATE_DETAILS_FAILURE: 'GET_TEMPLATE_DETAILS_FAILURE',

    UPLOAD_RESUME_REQUEST: 'UPLOAD_RESUME_REQUEST',
    UPLOAD_RESUME_SUCCESS: 'UPLOAD_RESUME_SUCCESS',
    UPLOAD_RESUME_FAILURE: 'UPLOAD_RESUME_FAILURE',

    APPLY_JOB_REQUEST: 'APPLY_JOB_REQUEST',
    APPLY_JOB_SUCCESS: 'APPLY_JOB_SUCCESS',
    APPLY_JOB_FAILURE: 'APPLY_JOB_FAILURE',

    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
    GET_COUNTRY_LIST_FAILURE: 'GET_COUNTRY_LIST_FAILURE',

    GET_STATE_LIST: 'GET_STATE_LIST',
    GET_STATE_LIST_SUCCESS: 'GET_STATE_LIST_SUCCESS',
    GET_STATE_LIST_FAILURE: 'GET_STATE_LIST_FAILURE',

    GET_CITY_LIST: 'GET_CITY_LIST',
    GET_CITY_LIST_SUCCESS: 'GET_CITY_LIST_SUCCESS',
    GET_CITY_LIST_FAILURE: 'GET_CITY_LIST_FAILURE',
}