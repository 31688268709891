import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingPageDecider from "./pages/landing-page-decider";


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<LandingPageDecider/>} />
      </Routes>
    </Router>
  );
};

export default App;
