import React, { useContext } from "react";
import parse from "html-react-parser";
import { TemplateContextProvider } from "../../contexts/TemplateContext";
import TemplateOneLogic from "./template-logic";
import QuestionReUsable from "../../components/re-usable/questions-resuable";
import "./url-template.scss";
import { ImageOrColorDecider, OpaqueDeciderForDescription } from "../../../helpers/container-style-decider";


const UrlTemplate = () => {
    const {
        headerResizableHeight,
        headerUploadedImage,
        siderLeftResizableWidth,
        siderLeftResizableHeight,
        siderLeftUploadedImage,
        siderRightResizableWidth,
        siderRightResizableHeight,
        siderRightUploadedImage,
        headerResizableWidth,
        headerAlignment,
        siderLeftAlignment,
        siderRightAlignment,
        footerAlignment,
        footerUploadedImage,
        footerResizableHeight,
        footerResizableWidth,
        backgroundColor,
        descValue,
        descriptionBackgroundColor,
        questionBackgroundColor,
        questionsFontColor,
        descWidth,
        listChecked,
        questions,
        templateData,
        headerPercentageWidth,
        footerPercentageWidth,
        backgroundImage,
        value,
        checkBoxOpaqueValue,
        questionFormOpaqueValue,
        setQuestions
    } = useContext(TemplateContextProvider);

    const headerAlignmentCss = () => {
        switch (headerAlignment) {
            case "center":
                return {
                    height: `${headerResizableHeight}px`,
                    width: `${headerPercentageWidth}%`,
                    marginLeft: "auto",
                    marginRight: "auto",
                };
            case "left":
                return {
                    height: `${headerResizableHeight}px`,
                    width: `${headerPercentageWidth}%`,
                    marginLeft: 0,
                    marginRight: "auto",
                };
            case "right":
                return {
                    height: `${headerResizableHeight}px`,
                    width: `${headerPercentageWidth}%`,
                    marginLeft: "auto",
                    marginRight: 0,
                };
        }
    };
    const siderLeftAlignmentCss = () => {
        switch (siderLeftAlignment) {
            case "center":
                return {
                    height: `${siderLeftResizableHeight}px`,
                    width: `${siderLeftResizableWidth}px`,
                    margin: "auto",
                };
            case "top":
                return {
                    height: `${siderLeftResizableHeight}px`,
                    width: `${siderLeftResizableWidth}px`,
                    marginTop: 0,
                    marginBottom: "auto",
                };
            case "bottom":
                return {
                    height: `${siderLeftResizableHeight}px`,
                    width: `${siderLeftResizableWidth}px`,
                    marginTop: "auto",
                    marginBottom: 0,
                };
        }
    };
    const siderRightAlignmentCss = () => {
        switch (siderRightAlignment) {
            case "center":
                return {
                    height: `${siderRightResizableHeight}px`,
                    width: `${siderRightResizableWidth}px`,
                    margin: "auto",
                };
            case "top":
                return {
                    height: `${siderRightResizableHeight}px`,
                    width: `${siderRightResizableWidth}px`,
                    marginTop: 0,
                    marginBottom: "auto",
                };
            case "bottom":
                return {
                    height: `${siderRightResizableHeight}px`,
                    width: `${siderRightResizableWidth}px`,
                    marginTop: "auto",
                    marginBottom: 0,
                };
        }
    };
    const footerAlignmentCss = () => {
        switch (footerAlignment) {
            case "center":
                return {
                    height: `${footerResizableHeight}px`,
                    width: `${footerPercentageWidth}%`,
                    marginLeft: "auto",
                    marginRight: "auto",
                };
            case "left":
                return {
                    height: `${footerResizableHeight}px`,
                    width: `${footerPercentageWidth}%`,
                    marginLeft: 0,
                    marginRight: "auto",
                };
            case "right":
                return {
                    height: `${footerResizableHeight}px`,
                    width: `${footerPercentageWidth}%`,
                    marginLeft: "auto",
                    marginRight: 0,
                };
        }
    };

    const OpaqueDeciderForQuestionForm = () => {
        if (!questionFormOpaqueValue) {
            return {
                background: questionBackgroundColor ? questionBackgroundColor : 'white'
            }
        }
    }

    return (
        <>
            <TemplateOneLogic />
            <div
                className="url-template-page">
                <div style={ImageOrColorDecider(value, backgroundImage, backgroundColor)}>
                    {headerUploadedImage && (
                        <div className="header">
                            <>
                                <img
                                    src={headerUploadedImage}
                                    alt="Uploaded"
                                    style={headerAlignmentCss()}
                                />
                            </>
                        </div>
                    )}
                    <div className="middle-container">
                        <div className="middle-container-content" style={{ display: "flex" }}>
                            {siderLeftUploadedImage && (
                                <div className="sider">
                                    <>
                                        <img
                                            src={siderLeftUploadedImage}
                                            alt="Uploaded"
                                            className="image-uploaded"
                                            style={siderLeftAlignmentCss()}
                                        />
                                    </>
                                </div>
                            )}
                            <div className="desc">
                                <div className="description-content" style={OpaqueDeciderForDescription(checkBoxOpaqueValue, descriptionBackgroundColor)}>
                                    {parse(`${descValue}`)}
                                </div>
                            </div>
                            <QuestionReUsable templateData={templateData} questions={questions} questionsFontColor={questionsFontColor} listChecked={listChecked} setQuestions={setQuestions} OpaqueDeciderForQuestionForm={OpaqueDeciderForQuestionForm} temlateType={2} noSpace={true} heading="SUBMIT" questionBackgroundColor={questionBackgroundColor} />
                            {siderRightUploadedImage && (
                                <div className="sider">
                                    <img
                                        src={siderRightUploadedImage}
                                        alt="Uploaded"
                                        className="image-uploaded"
                                        style={siderRightAlignmentCss()}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {footerUploadedImage && (
                        <div className="footer-pre">
                            <>
                                <img
                                    src={footerUploadedImage}
                                    alt="Uploaded"
                                    style={footerAlignmentCss()}
                                />
                            </>
                        </div>
                    )}
                </div>
            </div>
        </>

    );
};

export default UrlTemplate;
