import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { templateAction } from '../../actions/template-action';
import UrlTemplate from './one-template/url-template';
import { Skeleton } from 'antd';
import NotFoundPage from './page-not-found/page-not-found';
import TemplateTwo from './second-template/template-two';

const LandingPageDecider = () => {

    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [templateType, setTemplateType] = useState()
    const [loading, setLoading] = useState(true);
    const [isTemplateNotFound, setIsTemplateNotFound] = useState(false)

    // let templateURL = window.location.pathname.substring(1);
    let templateURL = window.location.href;
    if (templateURL !== undefined) {
        templateURL = templateURL.replace(/^https?:\/\//, '');
        templateURL = templateURL.replace(/\/$/, '');
    }

    const fetchSuccess = (res) => {
        setTemplateType(res?.data?.templateType)
        setLoading(false);
    }

    const fetchFail = (res) => {
        setLoading(false);
        setIsTemplateNotFound(true)
    };


    const templateDecider = (value) => {
        switch (value) {
            case 1: return {
                template: <UrlTemplate />
            }
            case 2:
                return {
                    template: <TemplateTwo />,
                }
        }
    }

    useEffect(() => {
        if (templateURL) {
            dispatch(templateAction.getTemplateDetails(encodeURIComponent(templateURL), fetchSuccess, fetchFail));
        }
    }, []);

    useEffect(() => {
        const templateData = templateDecider(templateType);
        setData(templateData);
    }, [templateType]);

    return (
        <Skeleton active loading={loading}>
            {isTemplateNotFound ? <NotFoundPage /> : data?.template}
        </Skeleton>
    )
};

export default LandingPageDecider;