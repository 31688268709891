import {
  Form,
  Input,
  Upload,
  Row,
  Col,
  Button,
  notification,
  Spin,
  Select,
} from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { templateAction } from "../../../actions/template-action";
import { DownloadOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import "./preview-input.scss";

export default function PreviewInputs({
  listChecked,
  form,
  fileList,
  setFileList,
  questionsFontColor,
  setUploadedCvURL,
  templateType = null,
  noSpace
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imageDisplay, setImageDisplay] = useState(false);

  const country = useSelector(
    (country) => country.getCountryreducer?.country?.data
  );
  const State = useSelector((state) => state.getStatereducer?.state?.data);
  const StateLoading = useSelector((state) => state.getStatereducer?.status);
  const city = useSelector((city) => city.getCityreducer?.city?.data);
  const cityLoading = useSelector((city) => city.getCityreducer?.status);

  useEffect(() => {
    dispatch(templateAction.getCountry());
  }, []);

  const uploadSuccess = (res) => {
    setLoading(false);
    setUploadedCvURL(res?.data?.Location);
    setImageDisplay(true);
    notification.success({
      message: "Resume Uploaded successfully",
    });
  };

  const uploadFail = (res) => {
    setLoading(false);
    setImageDisplay(false);
    notification.error({
      message: res?.response?.data?.message,
    });
  };

  const propsUpload = {
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      return false;
    },
    onChange(info) {
      setLoading(true);
      const { fileList } = info;
      setFileList(fileList.slice(-1));
      const uploadedResume = fileList[fileList?.length - 1].originFileObj;
      dispatch(
        templateAction.uploadCv(uploadedResume, uploadSuccess, uploadFail)
      );
    },
  };

  const fieldRules = (fieldName) => ({
    required: listChecked.includes(fieldName),
    message: "This field is required",
  });

  const onChangeCountry = (country, key) => {
    form.resetFields(["state", "city"]);
    dispatch(templateAction.getState(key?.key));
  };

  const onChangeState = (state, key) => {
    form.resetFields(["city"]);
    dispatch(templateAction.getCity(key?.key));
  };


  const placeholder = (heading) => {
    if (noSpace) {
      return (
        `${heading} ${listChecked.includes(heading) ? '*' : ''}`
      )
    } else {
      return heading;
    }
  }

  const label = (heading) => {
    if (noSpace) {
      return ""
    } else {
      return (
        <div style={{ color: questionsFontColor && questionsFontColor, marginLeft: '4px' }}> {heading}<span>{listChecked.includes(heading) && '*'}</span></div>
      )
    }
  }


  return (
    <div className={noSpace ? "preview-input-container-no-space" : "preview-input-container"}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            rules={[fieldRules("First Name")]}
            label={label('First Name')}
          >
            <Input placeholder={placeholder("First Name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            rules={[fieldRules("Last Name")]}
            label={label('Last Name')}
          >
            <Input placeholder={placeholder("Last Name")} />
          </Form.Item>
        </Col>
        <Col span={templateType === 2 && 24 || 12}>
          <Form.Item
            name="phone"
            rules={[
              {
                required: listChecked.includes("Phone Number"),
                message: "This field is required",
              },
              {
                pattern:
                  /^(([02-9]{1}))\(?([0-9]{2})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
                message: "Invalid phone number ",
              },
            ]}
            label={label("Phone Number")}
          >
            <Input minLength={10} maxLength={10} placeholder={placeholder("Phone Number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            rules={[
              {
                required: listChecked.includes("Email"),
                message: "This field is required",
              },
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
            ]}
            label={label("Email")}
          >
            <Input placeholder={placeholder('Email')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="country"
            rules={[
              {
                required: listChecked.includes("Country"),
                message: "This field is required",
              },
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please input alphabetical characters!",
              },
            ]}
            label={label('Country')}
          >
            <Select
              showSearch
              onChange={(value, key) => onChangeCountry(value, key)}
              placeholder={placeholder("Country")}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {country &&
                country.map((item, key) => {
                  return (
                    <Option key={item?.id} value={item.country}>
                      {item.country}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="state"
            rules={[fieldRules("State")]}
            label={label('State')}
          >
            <Spin spinning={StateLoading}>
              <Select
                showSearch
                onChange={(value, key) => {
                  form.setFieldsValue({ state: value });
                  onChangeState(value, key);
                }}
                placeholder={placeholder("State")}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {State &&
                  State.map((item, key) => {
                    return (
                      <Option key={item.stateId} value={item.stateName}>
                        {item.stateName}
                      </Option>
                    );
                  })}
              </Select>
            </Spin>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="city"
            rules={[fieldRules("City")]}
            label={label('City')}
          >
            <Spin spinning={cityLoading}>
              <Select
                showSearch
                size="medium"
                placeholder={placeholder("City")}
                onChange={(value) => form.setFieldsValue({ city: value })}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {city &&
                  city.map((item, key) => {
                    return (
                      <Option key={key} value={item}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </Spin>
          </Form.Item>
        </Col>
        <Col span={templateType === 2 && 24 || 12}>
          <Form.Item
            name="zipCode"
            rules={[
              {
                required: listChecked.includes("Zipcode"),
                message: "This field is required",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Enter only numeric values",
              },
              {
                whitespace: true,
                message: "This field can not be blank",
              },
            ]}
            label={label('Zipcode')}
          >
            <Input placeholder={placeholder("Zipcode")} />
          </Form.Item>
        </Col>
        {listChecked.includes("Upload Resume") && (
          <Col span={templateType === 2 && 24 || 12}>
            <Form.Item
              name="cvUrl"
              rules={[
                {
                  required: listChecked.includes("Upload Resume"),
                  message: "This field is required",
                },
              ]}
              label={label('Upload Resume')}
            >
              <Spin spinning={loading}>
                <Upload Upload {...propsUpload} accept=".pdf,.doc,.docx">
                  <Button
                    className="upload-button"
                    icon={<DownloadOutlined />}
                    style={{ borderRadius: "0px", fontsize: '1rem' }}
                  >
                    {placeholder('Upload Resume')}
                  </Button>
                </Upload>
                {imageDisplay && (
                  <div
                    style={{ color: questionsFontColor && questionsFontColor }}
                  >
                    {fileList?.[0]?.name}
                  </div>
                )}
              </Spin>
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
}
