import { useSelector } from "react-redux";
import { TemplateContextProvider } from "../../contexts/TemplateContext";
import { useContext, useEffect } from "react";
import { setFavicon } from "../../../helpers/set-chrome-icon";


export default function TemplateOneLogic() {
    const templateDetailsData = useSelector(state => state.getTemplateDetailsReducer?.data);
    const {
        setTemplateData,
        setHeaderUploadedImage,

        setSiderLeftUploadedImage,
        setSiderRightUploadedImage,
        setFooterUploadedImage,
        setDescValue,

        setHeaderResizableHeight,
        setHeaderResizableWidth,
        setHeaderAlignment,

        setSiderLeftResizableHeight,
        setSiderLeftResizableWidth,
        setSiderLeftAlignment,

        setSiderRightResizableHeight,
        setSiderRightResizableWidth,
        setSiderRightAlignment,

        setFooterResizableHeight,
        setFooterResizableWidth,
        setFooterAlignment,
        setListChecked,

        setQuestionBackgroundColor,
        setDescriptionBackgroundColor,
        setQuestionsFontColor,
        setQuestions,

        setDescWidth,
        setHeaderPercentageWidth,
        setFooterPercentageWidth,

        setValue,
        setCheckBoxOpaqueValue,
        setQuestionFormOpaqueValue,
        setBackgroundImage,
        setBackgroundColor,

        clientLogo,
        setClientLogo

    } = useContext(TemplateContextProvider);

    const setContactInfoGet = (templateDetailsData) => {
        const contactInfo = templateDetailsData?.contactInfo;
        if (contactInfo) {
            const trueKeys = Object.keys(contactInfo).filter(key => contactInfo[key] === true);
            const selectedKeys = trueKeys.map(item => {
                switch (item) {
                    case 'isRequireCity':
                        return 'City';
                    case 'isRequireEmail':
                        return 'Email';
                    case 'isRequireState':
                        return 'State';
                    case 'isRequireResume':
                        return 'Upload Resume';
                    case 'isRequireCountry':
                        return 'Country';
                    case 'isRequireZipCode':
                        return 'Zipcode';
                    case 'isRequireLastName':
                        return 'Last Name';
                    case 'isRequireFirstName':
                        return 'First Name';
                    case 'isRequirePhoneNumber':
                        return 'Phone Number';
                    default:
                        return '';
                }
            }).filter(item => item !== '');
            return selectedKeys;
        }
        return [];
    };


    useEffect(()=> {
        setFavicon(clientLogo);
    },[clientLogo])

    useEffect(() => {
        if (templateDetailsData) {
            const { header, footer, siderLeft, siderRight, descriptionStyles, questionFormStyle } = templateDetailsData.styles || {};
            
            setClientLogo(templateDetailsData?.client?.clientLogo);

            setTemplateData(templateDetailsData);
            setHeaderUploadedImage(templateDetailsData?.templateHeaderImg);
            setHeaderResizableHeight(header?.height);
            setHeaderResizableWidth(header?.width);
            setHeaderAlignment(header?.alignment ? header?.alignment : 'center');
            setHeaderPercentageWidth(header?.percentage)

            setFooterUploadedImage(templateDetailsData?.templateFooterImg);
            setFooterResizableHeight(footer?.height);
            setFooterResizableWidth(footer?.width);
            setFooterAlignment(footer?.alignment ? footer?.alignment : 'center');
            setFooterPercentageWidth(footer?.percentage)

            setSiderLeftUploadedImage(templateDetailsData?.templateLeftImg);
            setSiderLeftResizableHeight(siderLeft?.height);
            setSiderLeftResizableWidth(siderLeft?.width);
            setSiderLeftAlignment(siderLeft?.alignment ? siderLeft?.alignment : 'center');

            setSiderRightUploadedImage(templateDetailsData?.templateRightImg);
            setSiderRightResizableHeight(siderRight?.height);
            setSiderRightResizableWidth(siderRight?.width);
            setSiderRightAlignment(siderRight?.alignment ? siderRight?.alignment : 'center');

            setDescValue(templateDetailsData?.description);
            setDescWidth(descriptionStyles?.descriptionWidth);
            setDescriptionBackgroundColor(descriptionStyles?.backgroundColor && descriptionStyles?.backgroundColor);
            setCheckBoxOpaqueValue(descriptionStyles?.opaque);

            setQuestionBackgroundColor(questionFormStyle?.backgroundColor && questionFormStyle?.backgroundColor);
            setQuestionsFontColor(questionFormStyle?.fontColor);
            setQuestionFormOpaqueValue(questionFormStyle?.opaque);

            setBackgroundImage(templateDetailsData?.styles?.background_image);
            setValue(Number(templateDetailsData?.styles?.background_mode));
            setBackgroundColor(templateDetailsData?.styles?.backgroundColor && templateDetailsData?.styles?.backgroundColor)

            const selectedKeys = setContactInfoGet(templateDetailsData);
            setListChecked(selectedKeys);
            setQuestions(templateDetailsData?.questions);
        }
    }, [templateDetailsData])

    return null;
}