export const getDescription = (description) => {
    // Check if description is empty or just a line break
    if (!description || description.trim() === '<p><br></p>') {
        return null; // Return null if description is empty or just a line break
    } else {
        return description; // Return the description if it's not empty
    }
};

export const checkIfAnyStateHasValue = (states) => {
    for (const state of states) {
        if (state) {
            console.log(state, 'bhargav')
            return true;
        }
    }
    return false;
}
