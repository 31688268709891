import React, { useState } from "react";
export const TemplateContext2Provider = React.createContext();

const TemplateContext2 = ({ children }) => {

    const [clientLogo, setClientLogo] = useState(null)

    //images
    const [rowOneImageLink, setRowOneImageLink] = useState();
    const [rowTwoImageLink, setRowTwoImageLink] = useState();
    const [rowThreeImageLink, setRowThreeImageLink] = useState();
    const [rowFourImageLink, setRowFourImageLink] = useState();
    const [rowFiveImageLink, setRowFiveImageLink] = useState();
    const [rowSixImageLink, setRowSixImageLink] = useState();
    const [rowSevenImageLink, setRowSevenImageLink] = useState();
    const [rowEightImageLink, setRowEightImageLink] = useState();
    const [rowNineImageLink, setRowNineImageLink] = useState();
    const [rowTenImageLink, setRowTenImageLink] = useState();
    const [rowResizeImageLink, setRowResizeImageLink] = useState()
    const [middleContainerImageLink, setMiddleContainerImageLink] = useState()

    //description
    const [rowTwoDescription, setRowTwoDescription] = useState();
    const [rowThreeDescription, setRowThreeDescription] = useState();
    const [rowFourDescription, setRowFourDescription] = useState();
    const [rowFiveDescription, setRowFiveDescription] = useState();
    const [rowSixDescription, setRowSixDescription] = useState();
    const [rowSevenDescription, setRowSevenDescription] = useState();
    const [rowEightDescription, setRowEightDescription] = useState();
    const [rowResizeDescription, setRowResizeDescription] = useState();

    // background colors

    const [rowTwoBackgroundColor, setRowTwoBackgroundColor] = useState('white');
    const [rowThreeBackgroundColor, setRowThreeBackgroundColor] = useState('white');
    const [rowFourBackgroundColor, setRowFourBackgroundColor] = useState('white');
    const [rowFiveBackgroundColor, setRowFiveBackgroundColor] = useState('white');
    const [rowSixBackgroundColor, setRowSixBackgroundColor] = useState('white');
    const [rowSevenBackgroundColor, setRowSevenBackgroundColor] = useState('white');
    const [rowEightBackgroundColor, setRowEightBackgroundColor] = useState('white');
    const [rowResizeBackgroundColor, setRowResizeBackgroundColor] = useState('white');
    const [middleContainerBackgroundColor, setMiddleContainerBackgroundColor] = useState('white')

    //opaque values for description
    const [rowTwoOpaqueValue, setRowTwoOpaqueValue] = useState(false);
    const [rowThreeOpaqueValue, setRowThreeOpaqueValue] = useState(false);
    const [rowFourOpaqueValue, setRowFourOpaqueValue] = useState(false);
    const [rowFiveOpaqueValue, setRowFiveOpaqueValue] = useState(false);
    const [rowSixOpaqueValue, setRowSixOpaqueValue] = useState(false);
    const [rowSevenOpaqueValue, setRowSevenOpaqueValue] = useState(false);
    const [rowEightOpaqueValue, setRowEightOpaqueValue] = useState(false);
    const [rowResizeOpaqueValue, setRowResizeOpaqueValue] = useState(false);


    //values
    const [rowTwoValue, setRowTwoValue] = useState(1)
    const [rowFourValue, setRowFourValue] = useState(1)

    // others

    const [value, setValue] = useState(1);
    const [listChecked, setListChecked] = useState([]);
    const [widthPercentage, setWidthPercentage] = useState(70);
    const [questions, setQuestions] = useState([]);

    const [containerValue, setContainerValue] = useState(1)
    const [containerBackgroundColor, setContainerBackgroundColor] = useState('white');
    const [containerBackgroundImage, setContainerBackgroundImage] = useState()

    const [questionBackgroundColor, setQuestionBackgroundColor] = useState('white');
    const [questionsFontColor, setQuestionsFontColor] = useState('black');
    const [questionFormOpaqueValue, setQuestionFormOpaqueValue] = useState(false);
    const [questionBackgroundImage, setQuestionBackgroundImage] = useState();
    const [questionFormValue, setQuestionFormValue] = useState(1)


    const [rowOneButtonColor, setRowOneButtonColor] = useState('#11295a');
    const [rowOneButtonText, setRowOneButtonText] = useState('APPLY NOW');
    const [rowOneFontColor, setRowOneFontColor] = useState('white');
    const [rowThreeButtonColor, setRowThreeButtonColor] = useState('#11295a');
    const [rowThreeButtonText, setRowThreeButtonText] = useState('APPLY NOW');
    const [rowThreeFontColor, setRowThreeFontColor] = useState('white');
    const [rowFiveButtonColor, setRowFiveButtonColor] = useState('#11295a');
    const [rowFiveButtonText, setRowFiveButtonText] = useState('APPLY NOW');
    const [rowFiveFontColor, setRowFiveFontColor] = useState('white');

    const [templateData, setTemplateData] = useState(null);

    const [rowOneHeight, setRowOneHeight] = useState();
    const [rowOneWidthPercentage, setRowOneWidthPercentage] = useState();
    const [rowTwoHeight, setRowTwoHeight] = useState();
    const [rowTwoWidthPercentage, setRowTwoWidthPercentage] = useState();
    const [rowThreeHeight, setRowThreeHeight] = useState();
    const [rowThreeWidthPercentage, setRowThreeWidthPercentage] = useState();
    const [rowFourHeight, setRowFourHeight] = useState();
    const [rowFourWidthPercentage, setRowFourWidthPercentage] = useState();
    const [rowFiveHeight, setRowFiveHeight] = useState();
    const [rowFiveWidthPercentage, setRowFiveWidthPercentage] = useState();
    const [rowTenHeight, setRowTenHeight] = useState();
    const [rowTenWidthPercentage, setRowTenWidthPercentage] = useState();

    //hide applys
    const [rowOneHideApply, setRowOneHideApply] = useState(false);
    const [rowThreeHideApply, setRowThreeHideApply] = useState(false);
    const [rowFiveHideApply, setRowFiveHideApply] = useState(false);

    //custom form
    const [customForm, setCustomForm] = useState(false);
    const [customFormContent, setCustomFormContent] = useState()

    //rowNine
    const [rowNineAlignment, setRowNineAlignment] = useState('center');
    const [rowNineHeaderText, setRowNineHeaderText] = useState('APPLY NOW');
    const [rowNineFooterText, setRowNineFooterText] = useState('SUBMIT');

    return (
        <TemplateContext2Provider.Provider value={{

            //description content
            rowTwoDescription,
            setRowTwoDescription,
            rowThreeDescription,
            setRowThreeDescription,
            rowFourDescription,
            setRowFourDescription,
            rowFiveDescription,
            setRowFiveDescription,
            rowSixDescription,
            setRowSixDescription,
            rowSevenDescription,
            setRowSevenDescription,
            rowEightDescription,
            setRowEightDescription,
            rowResizeDescription,
            setRowResizeDescription,

            // description background colors
            rowTwoBackgroundColor,
            setRowTwoBackgroundColor,
            rowThreeBackgroundColor,
            setRowThreeBackgroundColor,
            rowFourBackgroundColor,
            setRowFourBackgroundColor,
            rowFiveBackgroundColor,
            setRowFiveBackgroundColor,
            rowSixBackgroundColor,
            setRowSixBackgroundColor,
            rowSevenBackgroundColor,
            setRowSevenBackgroundColor,
            rowEightBackgroundColor,
            setRowEightBackgroundColor,
            rowResizeBackgroundColor,
            setRowResizeBackgroundColor,
            middleContainerBackgroundColor,
            setMiddleContainerBackgroundColor,

            //description opaque values.
            rowTwoOpaqueValue,
            setRowTwoOpaqueValue,
            rowThreeOpaqueValue,
            setRowThreeOpaqueValue,
            rowFourOpaqueValue,
            setRowFourOpaqueValue,
            rowFiveOpaqueValue,
            setRowFiveOpaqueValue,
            rowSixOpaqueValue,
            setRowSixOpaqueValue,
            rowSevenOpaqueValue,
            setRowSevenOpaqueValue,
            rowEightOpaqueValue,
            setRowEightOpaqueValue,
            rowResizeOpaqueValue,
            setRowResizeOpaqueValue,

            //images
            rowOneImageLink,
            setRowOneImageLink,
            rowTwoImageLink,
            setRowTwoImageLink,
            rowThreeImageLink,
            setRowThreeImageLink,
            rowFourImageLink,
            setRowFourImageLink,
            rowFiveImageLink,
            setRowFiveImageLink,
            rowSixImageLink,
            setRowSixImageLink,
            rowSevenImageLink,
            setRowSevenImageLink,
            rowEightImageLink,
            setRowEightImageLink,
            rowNineImageLink,
            setRowNineImageLink,
            rowTenImageLink,
            setRowTenImageLink,
            rowResizeImageLink,
            setRowResizeImageLink,
            middleContainerImageLink,
            setMiddleContainerImageLink,

            //values
            value,
            setValue,
            containerValue,
            setContainerValue,
            rowTwoValue,
            setRowTwoValue,
            rowFourValue,
            setRowFourValue,

            //others
            listChecked,
            setListChecked,
            questions,
            setQuestions,
            widthPercentage,
            setWidthPercentage,
            containerBackgroundColor,
            setContainerBackgroundColor,
            containerBackgroundImage,
            setContainerBackgroundImage,
            questionBackgroundColor,
            setQuestionBackgroundColor,
            questionsFontColor,
            setQuestionsFontColor,
            questionFormOpaqueValue,
            setQuestionFormOpaqueValue,
            questionBackgroundImage,
            setQuestionBackgroundImage,
            questionFormValue,
            setQuestionFormValue,

            rowOneButtonColor,
            setRowOneButtonColor,
            rowOneButtonText,
            setRowOneButtonText,
            rowOneFontColor,
            setRowOneFontColor,
            rowThreeButtonColor,
            setRowThreeButtonColor,
            rowThreeButtonText,
            setRowThreeButtonText,
            rowThreeFontColor,
            setRowThreeFontColor,
            rowFiveButtonColor,
            setRowFiveButtonColor,
            rowFiveButtonText,
            setRowFiveButtonText,
            rowFiveFontColor,
            setRowFiveFontColor,

            templateData,
            setTemplateData,

            rowOneHeight,
            setRowOneHeight,
            rowOneWidthPercentage,
            setRowOneWidthPercentage,

            rowTwoHeight,
            setRowTwoHeight,
            rowTwoWidthPercentage,
            setRowTwoWidthPercentage,

            rowThreeHeight,
            setRowThreeHeight,
            rowThreeWidthPercentage,
            setRowThreeWidthPercentage,

            rowFourHeight,
            setRowFourHeight,
            rowFourWidthPercentage,
            setRowFourWidthPercentage,

            rowFiveHeight,
            setRowFiveHeight,
            rowFiveWidthPercentage,
            setRowFiveWidthPercentage,
            rowTenHeight,
            setRowTenHeight,
            rowTenWidthPercentage,
            setRowTenWidthPercentage,

            //hide Apply
            rowOneHideApply,
            setRowOneHideApply,
            rowThreeHideApply,
            setRowThreeHideApply,
            rowFiveHideApply,
            setRowFiveHideApply,

            clientLogo, 
            setClientLogo,

            //customForm
            customForm,
            setCustomForm,
            customFormContent,
            setCustomFormContent,

            //rowNine
            rowNineAlignment,
            setRowNineAlignment,
            rowNineHeaderText,
            setRowNineHeaderText,
            rowNineFooterText,
            setRowNineFooterText,
        }}>
            {children}
        </TemplateContext2Provider.Provider>
    )
}

export default TemplateContext2;