export const ImageOrColorDecider = (value, backgroundImage, backgroundColor) => {
    if (value == 0) {
        return {
            backgroundImage: `url(${backgroundImage})`,
            backgroundBlendMode: 'lighten',
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
        }
    } else {
        return {
            backgroundColor: backgroundColor,
        }
    }
}

export const OpaqueDeciderForQuestionForm = (questionFormOpaqueValue, questionBackgroundColor) => {
    if (!questionFormOpaqueValue) {
        return {
            background: questionBackgroundColor ? questionBackgroundColor : 'white'
        }
    }
};

export const OpaqueDeciderForDescription = (checkBoxOpaqueValue, descriptionBackgroundColor) => {
    if (!checkBoxOpaqueValue) {
        if (descriptionBackgroundColor) {
            return {
                background: descriptionBackgroundColor && descriptionBackgroundColor
            }
        } else {
            return {
                background: 'white'
            }
        }
    }
}

export const resizeImageOrColorDecider = (value, backgroundColor) => {
    if (value == 1) {
        return {
            backgroundColor: backgroundColor,
        }
    } else {
        return {
            backgroundColor: 'white'
        }
    }
}

