import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { useEffect, useState } from 'react';
import './QuestionPreview.scss'

const QuestionPreview = ({ dataQuestion, type, index, questionsFontColor, questions, setQuestions }) => {

    const [updatedDataQuestion, setUpdatedDataQuestion] = useState(null);
    const [checkBoxAnswers, setCheckBoxAnswers] = useState([]);
    const [radioAnswer, setRadioAnswer] = useState()
    const [answerText, setAnswerText] = useState();
    const [optionAnswer, setOptionAnswer] = useState();

    const getOptions = () => {
        const options = dataQuestion?.optionQuestions;
        const filteredOptions = options?.filter(item => item.hasOwnProperty('name'));
        if (filteredOptions?.length > 0) {
            return filteredOptions.map(item => item?.name)
        } else {
            return []
        }
    }

    const showQuestionLogic = (dataQuestion) => {
        if (type == 1) {
            if (dataQuestion.hasOwnProperty('name') && dataQuestion['name'] !== "") {
                setUpdatedDataQuestion(dataQuestion)
            } else {
                setUpdatedDataQuestion(null)
            }
        } else {
            const options = dataQuestion?.optionQuestions;
            const filteredOptions = options?.filter(item => item.hasOwnProperty('name') && item['name'] !== "");
            const newQuestion = {
                ...dataQuestion,
                optionQuestions: filteredOptions ? filteredOptions : []
            }
            setUpdatedDataQuestion(newQuestion);
        }
    }

    const labelStyle = { color: questionsFontColor && questionsFontColor, overflowWrap: 'anywhere' }
    const groupStyle = { color: questionsFontColor && questionsFontColor }

    const applyStyles = () => {
        if (type === 2) {
            const radioAreas = document.querySelectorAll('.radioArea');
            radioAreas.forEach(radioArea => {
                const radios = radioArea.querySelectorAll('.ant-radio-wrapper');
                radios.forEach(radio => {
                    radio.style.color = questionsFontColor || 'inherit';
                });
            });
        } else {
            const checkboxAreas = document.querySelectorAll('.checkbox-area');
            checkboxAreas.forEach(checkboxArea => {
                const checkBoxes = checkboxArea.querySelectorAll('.ant-checkbox-wrapper');
                checkBoxes.forEach(checkbox => {
                    checkbox.style.color = questionsFontColor || 'inherit';
                });
            });
        }
    }

    useEffect(() => {
        if (updatedDataQuestion) {
            applyStyles();
        }
    }, [updatedDataQuestion]);

    useEffect(() => {
        showQuestionLogic(dataQuestion)
    }, []);

    switch (type) {
        case 1:
            const handleInputChange = (e) => {
                const requiredQuestion = questions.find(question => question.id === dataQuestion.id);
                const updatedQuestion = {
                    ...requiredQuestion,
                    answerText: e.target.value
                };
                setQuestions(prevQuestions => prevQuestions.map(question => {
                    if (question.id === updatedQuestion.id) {
                        return updatedQuestion;
                    }
                    return question;
                }));
                setAnswerText(e.target.value);
            };
            return (
                updatedDataQuestion?.hasOwnProperty('name') && updatedDataQuestion['name'] !== "" ? (
                    <Form.Item
                        label={<div style={labelStyle}>{`${dataQuestion?.name ? dataQuestion?.name : ''}`}</div>}
                        name={`question_${dataQuestion.id}`}
                        rules={[
                            {
                                required: true,
                                message: 'Please provide an answer!',
                            },
                        ]}
                    >
                        <Input placeholder="Your answer here" onChange={handleInputChange} className="textArea" style={{ borderRadius: '0px' }} value={answerText} />
                    </Form.Item>
                ) : null
            );

        case 2:
            const radioChange = (e) => {
                const requiredQuestion = questions.find(question => question.id === dataQuestion.id);
                const requiredQuestionOptions = requiredQuestion.optionQuestions.map(option => ({
                    ...option,
                    isAnswer: e.target.value == option.name
                }));
                const updatedQuestion = {
                    ...requiredQuestion,
                    optionQuestions: requiredQuestionOptions
                };
                setQuestions(prevQuestions => prevQuestions.map(question => {
                    if (question.id === updatedQuestion.id) {
                        return updatedQuestion;
                    }
                    return question;
                }));
                setRadioAnswer(e.target.value);
            };
            return (
                updatedDataQuestion?.optionQuestions?.length > 0 && (
                    <Form.Item
                        label={<div style={labelStyle}>{`${dataQuestion?.name ? dataQuestion?.name : ''}`}</div>}
                        name={`question_${dataQuestion.id}`}
                        rules={[
                            {
                                required: true,
                                message: 'Please select an option!',
                            },
                        ]}
                    >
                        <Radio.Group style={groupStyle} options={getOptions()} onChange={radioChange} value={radioAnswer} className='radioArea' />
                    </Form.Item>
                )
            );

        case 3:
            const checkBoxChange = (checkedValues) => {
                const requiredQuestion = questions.find(question => question.id === dataQuestion.id);
                const requiredQuestionOptions = requiredQuestion.optionQuestions.map(option => ({
                    ...option,
                    isAnswer: checkedValues.includes(option.name)
                }));
                const updatedQuestion = {
                    ...requiredQuestion,
                    optionQuestions: requiredQuestionOptions
                };
                setQuestions(prevQuestions => prevQuestions.map(question => {
                    if (question.id === updatedQuestion.id) {
                        return updatedQuestion;
                    }
                    return question;
                }));
                setCheckBoxAnswers(checkedValues)
            }
            return (
                updatedDataQuestion?.optionQuestions?.length > 0 && (
                    <Form.Item
                        label={<div style={labelStyle}>{`${dataQuestion?.name ? dataQuestion?.name : ''}`}</div>}
                        name={`question_${dataQuestion.id}`}
                        rules={[
                            {
                                required: true,
                                message: 'Please select at least one option!',
                            },
                        ]}
                    >
                        <Checkbox.Group style={groupStyle} className='checkbox-area' onChange={checkBoxChange} options={getOptions()} value={checkBoxAnswers} />
                    </Form.Item>
                )
            );

        case 4:
            const optionChange = (value) => {
                const requiredQuestion = questions.find(question => question.id === dataQuestion.id);
                const requiredQuestionOptions = requiredQuestion.optionQuestions.map(option => ({
                    ...option,
                    isAnswer: value == option.name
                }));
                const updatedQuestion = {
                    ...requiredQuestion,
                    optionQuestions: requiredQuestionOptions
                };
                setQuestions(prevQuestions => prevQuestions.map(question => {
                    if (question.id === updatedDataQuestion.id) {
                        return updatedQuestion;
                    }
                    return question;
                }))
                setOptionAnswer(value);
            };
            const options = getOptions();
            return (
                updatedDataQuestion?.optionQuestions?.length > 0 && (
                    <Form.Item
                        label={<div style={labelStyle}>{`${dataQuestion?.name ? dataQuestion?.name : ''}`}</div>}
                        name={`question_${dataQuestion.id}`}
                        rules={[
                            {
                                required: true,
                                message: 'Please select an option!',
                            },
                        ]}
                    >
                        <Select showSearch allowClear placeholder="Select Answer" onChange={optionChange} value={optionAnswer} className='select-area'>
                            {options.map((option, index) => (
                                <Select.Option key={index} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )
            )

        default:
            return '';
    }
};

export default QuestionPreview;
